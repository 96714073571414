import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getGlobalConfig, publish } from '@tapendium/pwa-lib';

// Function to fetch supported languages from the backend
const fetchSupportedLanguages = async (id?, type?) => {
  try {
    const apiUrl = getGlobalConfig().apiUrl;
    const response = await fetch(
      `${apiUrl}/language/get-language-service?id=${id || 'default'}&type=${type || 'default'}`
    );
    const data = await response.json();
    const supportedLanguages = data.codes;
    localStorage.setItem('i18nextLngList', data.codes);
    publish('languageListUpdatedIntoLocalStorage', {
      currentLanguageList: data.codes,
    });
    await initializeI18next(supportedLanguages);
    publish('languageUpdatedIntoLocalStorage', {
      currentLanguage: localStorage.getItem('i18nextLng'),
    });
  } catch (error) {
    console.error('Failed to fetch supported languages:', error);
  }
};

// Initialize i18next with the supported languages
const initializeI18next = async (supportedLanguages) => {
  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
      {
        fallbackLng: 'en',
        supportedLngs: supportedLanguages,
        interpolation: {
          escapeValue: false,
        },
      },
      () => {
        const currentLanguage =
          localStorage.getItem('i18nextLng') || i18n.language;
        if (!supportedLanguages.includes(currentLanguage)) {
          i18n.changeLanguage('en');
        }
        localStorage.setItem('i18nextLng', i18n.language);
      }
    );
};

export { fetchSupportedLanguages };
